import { Link } from 'gatsby';
import React from 'react';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';

const NotFoundPage: React.FC = () => (
  <Layout>
    <SEO title="404: Not found" />

    <div
      className="flex items-center justify-between h-screen bg-purgatory-grey bg-bottom lg:bg-right bg-contain bg-no-repeat"
      style={{ backgroundImage: 'url(/images/404-bg.jpg)' }}
    >
      <div className="u-container px-6 lg:px-md mb-32 lg:mb-0">
        <div className="md:w-8/12 lg:w-6/12 xl:w-4/12 py-24">
          <div className="text-primary text-lg font-semibold mb-6">404</div>
          <h1 className="text-dark-grey u-h1 mb-5">
            Hmm, we can’t find that item
          </h1>
          <p className="text-dark-grey text-xl leading-8 mb-8">
            We tried to find the page you’re looking for but it doesn’t seem to
            exist. Turn right down the snack isle to get back.
          </p>
          <Link to="/" className="u-btn u-btn--green">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
